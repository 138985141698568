import React, { useEffect, useState } from "react"
import { editSchema } from '../../utils/validations'
import Swal from 'sweetalert2'
import '../../App.css'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, TextField, Box, InputLabel, FormControl } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { LoadScript, GoogleMap, Marker } from '@react-google-maps/api'
import { getComunas, getRegions } from "../../api/comunes/apiCalls"
import { addRequest } from "../../api/availability/apiCalls"
import { useHistory } from "react-router-dom"
import { isEmpty } from 'lodash'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { yupResolver } from "@hookform/resolvers/yup"
import { getStreets } from "../../api/streets/apiCalls"
import { HeaderTitle } from '../common/HeaderTitle'
import { Loader } from '@googlemaps/js-api-loader';


export const FormTechnicalFeasibility = () => {
  const history = useHistory()
  const [reload, setReload] = useState('')
  const [communes, setCommunes] = useState([])
  const [commune, setCommune] = useState(null)
  const [streets, setStreets] = useState([])
  const [street, setStreet] = useState(null)
  const [regions, setRegions] = useState([])
  const [region, setRegion] = useState(null)
  const [number, setNumer] = useState(null)
  const { register, handleSubmit, control, formState: { errors } } = useForm({ resolver: yupResolver(editSchema) })

  const [openModal, setOpenModal] = useState(false);

  //const [coordinates, setCoordinates] = useState({ lat: -34.397, lng: 150.644 });

  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });

  const [latitudgmaps, setLatGmaps] = useState(null);
  const [longitudgmaps, setLonGmaps] = useState(null);

  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    getRegions().then(({ data }) => {
      setRegions(data)
    })
  }, [reload])

  const handleChangeSelect = (name, obj) => {
    if (name === 'region') {
      setCommune(null);
      setStreet(null);
      setRegion(obj);
      getComunas(obj.id).then(({ data }) => {
        setCommunes(data);
      });
    }
    if (name === 'comuna') {
      setStreet(null);
      setCommune(obj);
    }
    if (name === 'calle') {
      setStreet(obj);
    }
  };

  const isAddressComplete = region && commune && street && number;
  const isFormComplete = region && commune && street && number && latitudgmaps && longitudgmaps;

  useEffect(() => {
    const { lat, lng } = coordinates || { lat: 0, lng: 0 };
    if (lat !== null && lng !== null) {
      geodir()
    }
  }, [coordinates]);

  const geodir = () => {
    if (isAddressComplete) {
      let direccionCompleta = `${street.calle} ${number}, ${commune.nombre}, ${region.nombre}`;
      geocodeAddress(direccionCompleta)
      handleOpenModal()
    }
  };

  const geocodeAddress = (direccionCompleta) => {
    console.log(direccionCompleta)
    if (!window.google || !window.google.maps || !window.google.maps.Map) {
      const loader = new Loader({
        apiKey: process.env.REACT_APP_API_KEY,
        version: 'weekly',
        libraries: ['places', 'maps', 'geocoding'],
      });

      loader
        .load()
        .then(() => {
          const { Geocoder } = window.google.maps;
          const geocoder = new Geocoder();

          geocoder.geocode({ 'address': direccionCompleta }, (results, status) => {
            console.log(results)
            console.log(status)
            if (status === 'OK') {
              let latLng = results[0].geometry.location;
              setCoordinates({
                lat: parseFloat(latLng.lat().toFixed(3)),
                lng: parseFloat(latLng.lng().toFixed(3)),
              });
            } else {
              Swal.fire('Error', 'No se pudo encontrar la dirección', 'error');
            }
          });
        })
        .catch((error) => {
          Swal.fire('Error', 'Hubo un problema al cargar la API de Google Maps', 'error');
        });
    }
  };

  const handleOpenModal = () => {
    setTimeout(function () {
      setOpenModal(true)
    }, 1000);
  };

  const handleCloseModal = () => {
    setLatGmaps(coordinates.lat)
    setLonGmaps(coordinates.lng)
    setOpenModal(false);
  };

  const handleMarkerDragEnd = (event) => {
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();

    const fixedNewLat = parseFloat(newLat.toFixed(3))
    const fixedNewLng = parseFloat(newLng.toFixed(3))

    setCoordinates({ lat: fixedNewLat, lng: fixedNewLng });
    setLatGmaps(fixedNewLat)
    setLonGmaps(fixedNewLng)
  };

  const onSubmit = (datos) => {
    const parseObject = {
      empresaMandante: datos.empresaMandante,
      rubro: datos.rubro,
      empresaSolicitante: datos.empresaSolicitante,
      contacto: datos.contacto,
      correo: datos.correo,
      telefono: datos.telefono,
      idRegion: datos.region.id,
      idComuna: datos.comuna.id,
      calle: datos.calle.calle,
      numero: datos.numero,
      rut: datos.rut,
      idUsuario: 1,
      tipoTrabajo: datos.tipoTrabajo,
      latitudgmaps,
      longitudgmaps
    }
    addRequest(parseObject)
      .then(({ data }) => {
        if (data.cod_error === 9000) {
          Swal.fire({
            title: `<strong><u>${data.mensaje_usr}</u></strong>`,
            icon: data.cod_error === 9000 ? 'success' : 'error',
            html: data.cod_error === 9000 ?
              `Estimado <b>${datos.empresaMandante},</b></br>` +
              `<p>Revisaremos tu requerimiento y te confirmaremos en un plazo no superior a 120 minutos.</p></br>` +
              `<b style='font-size: small'>En caso de ser necesaria consultas asociadas al proceso puede llamarnos a nuestro Centro de Operaciones de Emergencia al 600 600 9200 opción 3 correspondiente al servicio Llame Antes de excavar.<b>`
              : '',
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonColor: '#003087',
            cancelButtonColor: '#FF624E',
            confirmButtonText: 'Aceptar',
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload()
            }
          })
        } else {
          Swal.fire({
            title: `<strong><u>${data.mensaje_usr}</u></strong>`,
            icon: data.cod_error === 9099 ? 'error' : 'success',
            html: data.cod_error === 9099 ?
              `Estimado <b>${datos.empresaMandante},</b></br>` +
              `<p>Error en el envío de solicitud. Intente nuevamente.</p></br>` +
              `<b style='font-size: small'>En caso de ser necesaria consultas asociadas al proceso puede llamarnos a nuestro Centro de Operaciones de Emergencia al 600 600 9200 opción 3 correspondiente al servicio Llame Antes de excavar.<b>`
              : '',
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonColor: '#003087',
            cancelButtonColor: '#FF624E',
            confirmButtonText: 'Aceptar',
          }).then((result) => {
            if (result.isConfirmed) {
            }
          })
        }
      })
  }


  return (
    <form style={styles.fontMain}>
      <Box sx={{ width: '100%', marginLeft: 1 }} >
        <HeaderTitle title='Formulario de Ingreso de Solicitudes' />
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 4, sm: 2, md: 3 }}
          style={{ borderBottom: '1px solid transparent', borderLeft: '1px solid #B3B9C4', borderRight: '1px solid #B3B9C4', borderTop: '1px solid #B3B9C4', borderRadius: 10, padding: 2, marginTop: 40, background: 'linear-gradient(90deg, #D5CD06 -0.03%, #C2C40E 11.98%, #90B025 36.98%, #428E4A 71.98%, #007369 99.98%)' }}
        >
          <Grid item xs={12}
            style={{ borderBottom: '1px solid transparent', borderLeft: '1px solid #B3B9C4', borderRight: '1px solid #B3B9C4', borderTop: '1px solid #B3B9C4', borderRadius: 10, padding: 10, background: '#FFFFFF' }}
          >
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={6}>
                <TextField
                  {...register('empresaMandante')}
                  error={!!errors.empresaMandante}
                  helperText={errors.empresaMandante?.message}
                  label={'Empresa Mandante'}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  {...register('rubro')}
                  error={!!errors.rubro}
                  helperText={errors.rubro?.message}
                  label={'Rubro Empresa Mandante'}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  {...register('empresaSolicitante')}
                  error={!!errors.empresaSolicitante}
                  helperText={errors.empresaSolicitante?.message}
                  label={'Empresa Solicitante'}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  {...register('contacto')}
                  error={!!errors.contacto}
                  helperText={errors.contacto?.message}
                  label={'Nombre del contacto'}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel htmlFor="rut" style={{ display: 'none' }}>RUT</InputLabel>
                <TextField
                  {...register('rut')}
                  label={'Rut'}
                  placeholder={'Rut sin puntos y con guión'}
                  error={!!errors.rut}
                  helperText={errors.rut?.message}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  {...register('correo')}
                  error={!!errors.correo}
                  helperText={errors.correo?.message}
                  label={'E-Mail'}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  {...register('telefono')}
                  error={!!errors.telefono}
                  placeholder={'Número de largo 9'}
                  helperText={errors.telefono?.message}
                  label="Teléfono"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="region"
                  render={({ field: { onChange } }) => (
                    <Autocomplete
                      options={regions}
                      getOptionLabel={(option) => option.id ? `${option.id} - ${option.nombre}` : ''}
                      onChange={(_, value) => {
                        if (!isEmpty(value)) {
                          handleChangeSelect("region", value)
                        }
                        onChange(value)
                      }}
                      onInputChange={() => onChange(region)}
                      renderInput={params => <TextField
                        {...params}
                        error={!!errors.region}
                        inputProps={{ ...params.inputProps }}
                        helperText={errors.region?.message} label="Seleccione Región"
                      />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="comuna"
                  render={({ field: { onChange } }) => (
                    <Autocomplete
                      value={commune}
                      options={communes}
                      getOptionLabel={(option) => `${option.id} - ${option.nombre}`}
                      onChange={(_, value) => {
                        if (!isEmpty(value)) {
                          handleChangeSelect('comuna', value)
                        }
                        onChange(value)
                      }}
                      onInputChange={() => onChange(commune)}
                      renderInput={(params) => <TextField
                        {...params}
                        error={!!errors.comuna}
                        inputProps={{ ...params.inputProps, autoComplete: 'new-password' }}
                        helperText={errors.comuna?.message} label="Seleccione Comuna"
                      />}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="calle"
                  render={({ field: { onChange } }) => (
                    <Autocomplete
                      value={street}
                      options={streets}
                      filterOptions={(x) => x}
                      onInputChange={(_, value) => {
                        if (commune && commune.id && value) {
                          getStreets({
                            filter: value,
                            comuna: commune.id,
                            cantidad: 20
                          }).then((response) => {
                            setStreets(response.data)
                          })

                          if (!isEmpty(value)) {
                            const newStreetObj = {
                              callId: 0,
                              calle: value,
                              calleBuscada: null
                            };
                            handleChangeSelect("calle", newStreetObj)
                            onChange(newStreetObj)
                          }
                        }
                      }}
                      getOptionLabel={(option) => `${option.calle}`}
                      renderInput={(params) => <TextField
                        {...params}
                        error={!!errors.calle}
                        helperText={errors.calle?.message} label="Seleccione Calle"
                      />}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  {...register('numero')}
                  error={!!errors.numero}
                  helperText={errors.numero?.message}
                  label="Número de dirección"
                  fullWidth
                  onChange={(e) => setNumer(e.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <Button variant="outlined" color="primary" onClick={geodir} style={{ width: "100%", height: "93%" }} disabled={!isAddressComplete}>Confirmar Dirección</Button>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...register('tipoTrabajo')}
                  error={!!errors.tipoTrabajo}
                  label="Tipo de Trabajo"
                  multiline
                  fullWidth
                  maxRows={2}
                  inputProps={{ maxLength: 250 }}
                />
              </Grid>
            </Grid></Grid> </Grid>

        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 4, sm: 2, md: 3 }}
          style={{ borderBottom: '1px solid #B3B9C4', borderLeft: '1px solid #B3B9C4', borderRight: '1px solid #B3B9C4', borderTop: '1px solid transparent', borderRadius: 10, padding: 2, marginTop: -1, background: 'linear-gradient(90deg, #D5CD06 -0.03%, #C2C40E 11.98%, #90B025 36.98%, #428E4A 71.98%, #007369 99.98%)' }}
        >
          <Grid item xs={12}
            style={{ borderBottom: '1px solid #B3B9C4', borderLeft: '1px solid #B3B9C4', borderRight: '1px solid #B3B9C4', borderTop: '1px solid transparent', borderRadius: 10, padding: 10, background: '#FFFFFF' }}
          >
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12}>
                <Stack direction="row" spacing={2} justifyContent="center">
                  <FormControl>
                    <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)} disabled={!isFormComplete}>Enviar</Button>
                  </FormControl>
                </Stack>
              </Grid>

            </Grid></Grid></Grid>

      </Box>

      <Dialog open={openModal} onClose={handleCloseModal} maxWidth="md" onBackdropClick="false" fullWidth>
        <DialogTitle>Por favor, indique el punto más cercano al que desea hacer la excavación:</DialogTitle>
        <DialogContent>
          <Grid container style={{ height: '400px', width: '100%' }}>
            <Grid item xs={12} style={{ marginBottom: '10px' }}>
              <span>Latitud: {coordinates.lat}</span>
              <br></br>
              <span>Longitud: {coordinates.lng}</span>
              <br></br>
            </Grid>
            <GoogleMap
              mapContainerStyle={{ height: '90%', width: '100%' }}
              center={coordinates}
              zoom={15}
              googleMapsApiKey={API_KEY}
            >
              <Marker
                position={coordinates}
                draggable={true}
                onDragEnd={handleMarkerDragEnd}
              />
            </GoogleMap>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">Confirmar Dirección</Button>
        </DialogActions>
      </Dialog>
    </form>
  )
}



const styles = {
  fontMain: {
    fontFamily: 'Poppins, sans-serif',
  },
  fontTitle: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 500,
    fontSize: '1.286em'
  }
}